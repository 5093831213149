import React from 'react';

function Socials(){
    return (
   <div>
       <a className="link-text" href = "mailto: mzthomas96@gmail.com"><img src="/images/gmail.png" ></img>Email me!</a><br /><br />
       <a className="link-text" href = "https://github.com/mzthomas961?tab=repositories"><img src="/images/github.png" ></img> Github</a><br /> <br />
       <a className="link-text" href = "https://mzthomas96.medium.com/"><img src="/images/medium.png" ></img> Medium</a>

   </div>
    )
}
export default Socials